// ! IMPLEMENTATION
// import { useState } from 'react';
// import Modal from './Modal';

// export default function ToggleModal() {
// const [isOpen, setIsOpen] = useState<boolean>(false);
// return (
// <>
// <div id='modal-button-wrapper' onClick={() => console.log('clicked')}>
// <button onClick={() => setIsOpen(true)}>Open Modal</button>

// <Modal
// headline="Hello!, I'm a modal!"
// open={isOpen}
// onClose={() => setIsOpen(false)}>
// Fancy Modal
// </Modal>
// </div>
// </>
// );
// }

import ReactDom from 'react-dom';
import Button from '../ui/Button';
import debugThis from '../../utils/helpers';

const MODAL_STYLES = {
	// position: 'fixed',
	// top: '50%',
	// left: '50%',
	// transform: 'translate(-50%, -50%)',
};

const OVERLAY_STYLES = {
	// position: 'fixed',
	// top: 0,
	// left: 0,
	// right: 0,
	// bottom: 0,
	backgroundColor: 'rgba(0, 0, 0, .7)'
};

interface ModalPropTypes {
	children: React.ReactNode;
	isOpen: boolean;
	headline: string;
	onClose?: () => void;
	actionBtnText?: string;
	handleAction?: () => void;
	showCloseBtn?: boolean;
}

export default function Modal({
	actionBtnText,
	children,
	isOpen,
	handleAction,
	headline,
	onClose,
	showCloseBtn = true
}: ModalPropTypes) {
	const modalPortal = document.getElementById('modal') as HTMLElement;
	if (!isOpen) return null;

	const handleSubmit = () => {
		if (handleAction !== undefined) {
			handleAction();
		}
	};

	return ReactDom.createPortal(
		<>
			<div
				id='modal-overlay'
				style={OVERLAY_STYLES}
				className=' fixed top-o left-0 bottom-0 right-0 h-full z-50'
			/>
			<div
				id='modal-content'
				// className='opacity-0 transform -translate-y-full scale-150  relative  h-1/2 md:h-3/4  transition-opacity duration-300'
				className='rounded shadow-lg w-10/12 md:w-3/4 lg:w-1/2  bg-white mx-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-50 py-4 px-6'
				style={MODAL_STYLES}>
				<div className='modal-header'>
					<h3 className='flex mt-4 mb-0 text-xl'>
						{/* // TODO: Re-enable icon */}
						{/* <svg
							xmlns='http://www.w3.org/2000/svg'
							viewBox='0 0 24 24'
							fill='currentColor'
							className='w-6 mr-2 text-brand-400'>
							<path d='M19.006 3.705a.75.75 0 00-.512-1.41L6 6.838V3a.75.75 0 00-.75-.75h-1.5A.75.75 0 003 3v4.93l-1.006.365a.75.75 0 00.512 1.41l16.5-6z' />
							<path
								fillRule='evenodd'
								d='M3.019 11.115L18 5.667V9.09l4.006 1.456a.75.75 0 11-.512 1.41l-.494-.18v8.475h.75a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H3v-9.129l.019-.006zM18 20.25v-9.565l1.5.545v9.02H18zm-9-6a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h3a.75.75 0 00.75-.75V15a.75.75 0 00-.75-.75H9z'
								clipRule='evenodd'
							/>
						</svg> */}
						{headline}
					</h3>
				</div>
				<div className='modal-body py-4'>{children}</div>
				<div className='modal-footer'>
					<div className='flex flex-row justify-end flex-wrap'>
						{actionBtnText !== undefined && (
							<Button
								bgColor='bg-brand-500'
								hoverBgColor='hover:bg-brand-700'
								icon='send'
								iconFam='material-icons'
								iconPosition='right'
								classes='rounded px-4 py-2 transition duration-300 ease-in-out hover:bg-brand-700 mr-2'
								handleClick={handleSubmit}
								title={actionBtnText}
							/>
						)}
						{showCloseBtn && onClose !== undefined && (
							<Button
								bgColor='bg-gray-500'
								hoverBgColor='hover:bg-gray-600'
								classes='rounded px-4 py-2 transition duration-300 ease-in-out hover:bg-brand-700'
								handleClick={onClose}
								title='Cancelar'
							/>
						)}
					</div>
				</div>
			</div>
		</>,
		modalPortal
	);
}
