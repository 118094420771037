import { Link, useLocation, useParams } from 'react-router-dom';
import useUserContext from '../../../api/UserContext';
import useHideElementOnMount from '../../../hooks/useHideElementOnMount';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../api/AuthContext';
import MainTag from '../../../layout/Main';
import Headline from '../../../components/ui/Headline';
import MaterialIcons from '../../../components/ui/icons/MaterialIcons';
import Logo from '../../../components/Logo';
import Card from '../../../components/ui/Card';
import { useLoadingContext } from '../../../context/LoadingContext';
import routes from '../../../config/routes';

const CheckoutSuccess = () => {
	useHideElementOnMount('header');
	useHideElementOnMount('footer');
	const { setLoading, setLoadingMessage } = useLoadingContext();
	const { currentUser, updateToPaidViaStripe } = useUserContext();
	const { redirectToLoginIfUnauthorized } = useAuthContext();
	const location = useLocation();
	const { id } = useParams();
	const [wasMadeClient, setWasMadeClient] = useState(false);

	useEffect(() => {
		setLoadingMessage('Un momento por favor...');
		setLoading(true);
		if (currentUser === null) {
			redirectToLoginIfUnauthorized(location.pathname);
		} else {
			if (id === currentUser.uid) {
				setLoadingMessage('Confirmando datos de pago...');
				const source = location.search.split('?pymt_source=')[1];
				if (source === 'stripe') {
					console.log('Stripe checkout success');
					updateToPaidViaStripe()
						.then(() => {
							setLoading(false);
							setWasMadeClient(true);
						})
						.catch((error) => {
							setLoading(false);
							console.error('Error updating user to paid via stripe: ', error);
						});
				} else if (source === 'paypal') {
					console.log('Paypal checkout success');
				} else {
					// HANDLE THIS CASE
				}
			}
		}
	}, [currentUser]);

	if (currentUser === null) return null;
	return (
		<MainTag>
			<div className='flex flex-col items-center mb-4 mt-10  mx-auto'>
				<div className='container'>
					<Card classes='px-8 py-20 border rounded-md shadow-xl'>
						<Logo useDarkLogo={true} />
						<Headline
							classes='text-green-600'
							title='¡Gracias por tu compra!'
							type='h2'>
							<MaterialIcons icon='check_circle' classes='text-4xl mr-2' />
						</Headline>
						<div className='mt-8 fsize-medium'>
							<p>
								Nos place informarte que hemos recibido tu pago
								satisfactoriamente. Tu tarjeta presentará un cargo a nombre de{' '}
								<span>&quot;PREPMED&quot;</span> o{' '}
								<span>&quot;PREPMEDRD.COM&quot;</span>.
							</p>
							<p>
								Queremos aprovechar la oportunidad para darte una cálida
								bienvenida y desearte la mejor de las suertes al momento de
								tomar el examen. ¡Gracias por confiar en nosotros!
							</p>
							<p>
								Has tomado una excelente decisión y en breve podrás empezar a
								utilizar nuestra plataforma para prepararte a tomar el examen
								que decidirá tu futuro como médico en la República Dominicana.
							</p>
							<p>Presiona el botón debajo para continuar.</p>
						</div>
						{wasMadeClient && (
							<div className='flex justify-end items-end mt-4'>
								<Link
									to={routes.APP.INDEX}
									className='btn bg-brand-500 flex justify-end items-end'>
									Panel Principal
									<i className='material-icons ml-2'>send</i>
								</Link>
							</div>
						)}
					</Card>
				</div>
			</div>
		</MainTag>
	);
};
export default CheckoutSuccess;
